.sidebar .MuiPaper-root {
  border-color: #757575;
}

.sidebar .MuiToolbar-root {
  min-height: 84px;
}

.sidebar .MuiList-root {
  padding: 22px 0;
}

.sidebar .MuiButtonBase-root {
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-left: 9px;
  margin-right: 9px;
}

.sidebar .MuiButtonBase-root .MuiListItemText-root .MuiTypography-root {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1C1B1F;
}

.sidebar .MuiButtonBase-root .MuiListItemIcon-root {
  color: #000000;
}

.sidebar .MuiButtonBase-root.Mui-selected,
.sidebar .MuiButtonBase-root:hover,
.sidebar .MuiButtonBase-root.Mui-selected:hover {
  background-color: rgba(255, 198, 0, 0.38);
}

.sidebar .MuiButtonBase-root.Mui-selected .MuiListItemText-root .MuiTypography-root,
.sidebar .MuiButtonBase-root.Mui-selected .MuiListItemIcon-root,
.sidebar .MuiButtonBase-root:hover .MuiListItemText-root .MuiTypography-root,
.sidebar .MuiButtonBase-root:hover .MuiListItemIcon-root {
  color: #614b00;
}

.sidebar .MuiDivider-root {
  border-color: #757575;
}

@media screen and (max-width: 767px) {
  .sidebar .MuiList-root {
    padding: 0;
  }
}