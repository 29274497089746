.stepper-wrap .MuiStepper-root {
  align-items: flex-start;
}

.stepper-wrap .MuiStepper-root .MuiStep-root {
  margin-right: 40px;
}

.stepper-wrap .MuiStepLabel-iconContainer {
  display: none;
}

.stepper-wrap .MuiStepLabel-label {
  height: 50px;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.25px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: normal;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}

.stepper-wrap .MuiStepLabel-label.Mui-active {
  font-size: 36px;
  font-weight: 600;
}

.stepper-wrap .MuiStepLabel-label>span {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepper-wrap .MuiStepLabel-label>span:first-child {
  width: 100%;
}

.stepper-wrap .MuiStepLabel-label span:last-child {
  padding: 0 10px;
  position: absolute;
  right: -45px;
  top: 50%;
  transform: translateY(-50%);
}

.stepper-wrap .MuiStepLabel-label svg {
  vertical-align: middle;
  height: 32px;
  width: 32px;
  margin-bottom: -2px;
}

.stepper-wrap .campaign-name-label {
  font-size: 16px;
  color: #000000;
  text-align: center;
  font-style: italic;
}

.stepper-wrap .campaign-post-label {
  white-space: nowrap;
}

.stepper-wrap .MuiStepper-root .MuiStep-root:last-child .MuiStepLabel-label span svg {
  display: none;
}

.MuiTable-root .selectedRow td,
.MuiTable-root .selectedRow th {
  border-top: 1px solid #ffc600 !important;
  border-bottom: 1px solid #ffc600 !important;
}

.campaign-restaurants-label .MuiAvatar-colorDefault {
  margin-left: 0 !important;
}

/* FILE UPLOAD */

.file-upload-wrap {
  position: relative;
  border: 1px dashed #414141;
  border-radius: 20px;
  padding: 15px;
  min-height: 315px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload-drag {}

.file-upload-drag>svg {
  position: absolute;
  top: 10px;
  left: 10px;
}

.file-upload-seemore {
  border: 1px solid #A1A1A1;
  border-radius: 15px;
  min-height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.file-upload-seemore p {
  color: #616161;
  margin-top: 15px;
  margin-bottom: 0;
}

.file-upload-seemore svg {
  color: #616161;
  height: 40px;
  width: 40px;
}

.file-upload-drag .file-upload-seemore {
  min-height: 255px;
}

.file-upload-drag .upload-checkbox-select {
  position: absolute;
}

.file-upload-drag .upload-checkbox-video::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(89, 89, 89, 0.45);
}

.file-upload-drag .upload-checkbox-video .icon-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  color: #ffffff;
}

.icon-excel {
  position: absolute;
  left: 0;
  top: 10px;
}

.file-upload-excel-wrap {
  min-height: 200px;
}

.modal-content-upload .MuiDialogContent-root {
  padding-left: 15px;
  padding-right: 15px;
}

.modal-content-upload .MuiTabs-flexContainer {
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-bottom: 15px;
}

.modal-content-upload .file-upload-drag .MuiButton-containedSecondary {
  line-height: normal;
  font-size: 14px;
}

.file-upload-drag .upload-checkbox-video .icon-play {
  width: 40px;
  height: 40px;
}