.btn-sign.MuiButtonBase-root {
  padding: 11px 15px;
}

.btn-sign.MuiButtonBase-root .MuiAvatar-square {
  height: auto;
  width: auto;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .btn-sign.MuiButtonBase-root {
    padding: 6px 15px;
  }

  .btn-sign.MuiButtonBase-root .MuiAvatar-square {
    left: 5px;
  }

  .btn-sign.MuiButtonBase-root .MuiAvatar-square img {
    width: 60%;
  }
}